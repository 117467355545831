import React, { ReactElement, useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { Button } from '~components/homepage/styled/shared'

import { palette, mediaQueries } from '~components/homepage/styled/constants'
import LanguageContext from '../context/language-context'

const PopupWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  background: ${palette.beige};
  z-index: 999;
  letter-spacing: 1px;
`

const PopupContent = styled.div`
  position: relative;
  max-width: 980px;
  margin: 0 auto;
  font-size: 10px;
  text-align: center;
  padding: 40px 20px 20px 33px;

  @media (max-width: ${mediaQueries.small}) {
    & {
      padding: 55px 60px 20px 60px;
    }
  }

  a {
    margin-top: 10px;
    font-family: 'Averta-Bold', sans-serif;
  }
`

const CrossClose = styled.div`
  transform: rotate(45deg);
  position: absolute;
  right: 0;
  cursor: pointer;

  @media (max-width: ${mediaQueries.small}) {
    & {
      right: 20px;
      top: 26px;
    }
  }

  &::after,
  &::before {
    width: 14px;
    height: 1px;
    background: ${palette.darkBlue};
    content: '';
    display: block;
    transform-origin: 50% 50%;

    @media (max-width: ${mediaQueries.small}) {
      & {
        width: 19px;
      }
    }
  }

  &::before {
    transform: rotate(90deg);
    position: relative;
    top: 1px;
  }
`

const CookiesPopup = (): ReactElement => {
  const { t } = useContext(LanguageContext)
  const [accepted, setAccepted] = useState(false)

  const acceptCookies = (): void => {
    // eslint-disable-next-line no-undef
    localStorage.setItem('cookies_accepted', 'true')
    setAccepted(true)
  }

  useEffect((): void => {
    // eslint-disable-next-line no-undef
    setAccepted(!!localStorage.getItem('cookies_accepted'))
  }, [accepted])

  const renderContent = _.map(
    t('cookies.content', { returnObjects: true }),
    (p: string, idx: number): ReactElement => <div key={idx}>{p}</div>
  )

  const renderPopup: ReactElement | null = accepted ? null : (
    <PopupWrapper>
      <PopupContent>
        <CrossClose onClick={(): void => acceptCookies()} />
        {renderContent}
        <br />
        <Button onClick={(): void => acceptCookies()}>
          {t('cookies.button')}
        </Button>
      </PopupContent>
    </PopupWrapper>
  )

  return renderPopup
}

export default CookiesPopup
