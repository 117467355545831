import React from 'react'

interface Localizations {
  PL: string
  EN: string
}

const localizations: Localizations = {
  PL: 'PL',
  EN: 'EN',
}

const LanguageContext = React.createContext({
  t: undefined,
  lang: localizations.PL,
})

export default LanguageContext
