import styled from 'styled-components'
import React, { ReactElement } from 'react'

import Img from 'gatsby-image'
import {
  Align,
  AlignProp,
  ContentContainerProps,
  ContentHeaderProps,
  ResponsiveImageProps,
  SectionWrapperProps,
  TitleHeaderProps,
} from '~components/homepage/styled/types'
import { SVGImage } from '~components/homepage/svg/SVGImage'

import { Collapse } from 'antd'
import _ from 'lodash'
import * as Constant from './constants'
import { mediaQueries } from './constants'
import { KlosySVGId } from '../svg/types/svg'

const { darkGray, darkBlue, navyBlue, darkBiege } = Constant.palette

const { Panel } = Collapse

export const Container = styled.div`
  margin: 0 auto;
`

export const SectionWrapper = styled.div<SectionWrapperProps>`
  background-color: ${({ bgColor }): string => bgColor};
  width: 100%;

  @media (max-width: ${Constant.mediaQueries.small}) {
    .ant-row {
      margin: 0 !important;
    }
  }
`

export const ContentContainer = styled.div<ContentContainerProps>`
  max-width: ${({ maxWidth }): string => maxWidth || Constant.appMaxWidth};
  margin: 0 auto;
  padding-top: ${({ padding }): string => (padding ? `${padding}` : '0px;')};
  padding-bottom: ${({ padding }): string => (padding ? `${padding}` : '0px;')};
  overflow: hidden;
`

export const CSSlink = styled.a`
  color: inherit;

  :hover {
    color: inherit;
  }
`

export const PurifyLink = styled.span`
  a {
    color: ${navyBlue};
    letter-spacing: ${Constant.letterSpacing};
    display: inline-block;
    position: relative;
    padding: 15px 0;

    :hover {
      color: ${navyBlue};
    }

    :hover::after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    :after {
      content: '';
      position: absolute;
      left: 0;
      display: inline-block;
      height: 0.8em;
      width: 100%;
      border-bottom: 1px solid ${navyBlue};
      margin-top: 10px;
      opacity: 0;
      -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
      transition: opacity 0.35s, transform 0.35s;
      -webkit-transform: scale(0, 1);
      transform: scale(0, 1);
    }
  }
`

export const ResponsiveImage = styled.div<ResponsiveImageProps>`
  height: ${({ height }): string => height};
  width: ${({ width = '100%' }): string => width};
  background-image: url(${({ src }): string => src});
  background-size: cover;
  background-position: ${({ center }): string => (center ? 'center' : 'unset')};
`

export const DefaultImage = styled(Img)`
  width: 100%;
  height: 100%;
`

export const Button = styled.a`
  display: inline-block;
  border: 1px solid ${darkBlue};
  padding: 8px 25px;
  color: ${darkBlue};
  padding-top: 10px;
  font-size: 10px;
  transition: 0.3s;
  font-family: 'Averta-Bold', sans-serif;
  letter-spacing: ${Constant.letterSpacing};

  :hover {
    background: ${darkBlue};
    color: white;
  }
`

export const ContentHeader = styled.div<ContentHeaderProps & AlignProp>`
  color: ${darkBlue};
  font-size: ${({ size = '30px' }): string => size};
  font-weight: normal;
  letter-spacing: ${Constant.letterSpacing};
  margin-bottom: ${({ mB = '0' }): string => mB};
  text-align: ${({ align = Align.left }): Align => align};
`

export const DarkButton = styled(Button)`
  background: ${navyBlue};
  color: white;
  letter-spacing: ${Constant.letterSpacing};
  padding: auto 40px;
  font-size: 10px;
  font-family: 'Averta-Semibold', sans-serif;

  &:hover {
    color: white;
  }
`

export const TitleHeader = styled.div<TitleHeaderProps & AlignProp>`
  color: ${({ color = navyBlue }): string => color};
  margin: ${({ margin = 'auto' }): string => margin};
  font-size: ${({ size = '11px' }): string => size};
  letter-spacing: ${Constant.letterSpacing};
  padding-bottom: 5px;
  text-align: ${({ align = Align.left }): Align => align};
`

export const TitleBold = styled.p<TitleHeaderProps & AlignProp>`
  font-size: ${({ size = '20px' }): string => size};
  color: ${darkGray};
  text-align: ${({ align = Align.center }): Align => align};
  font-family: 'Averta-Bold', sans-serif;
`

interface PanelGroup {
  title: string
  panels: string[]
}

const StyledCollapse = styled(Collapse)`
  margin-bottom: 45px !important;

  &.ant-collapse {
    background-color: transparent;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }

  & > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    font-size: 15px;
    padding-left: 0 !important;
    padding-right: 25px !important;
    color: ${darkBlue};
  }

  &.ant-collapse > .ant-collapse-item:last-child {
    border-radius: 0 0 0 0;
  }

  .ant-collapse-content {
    font-size: 13px;
    background: transparent;
    border-top: none;

    color: ${darkBiege};
  }

  & > .ant-collapse-item-active {
    .ant-collapse-extra {
      svg {
        display: none;
      }

      &::after {
        content: '';
        width: 14px;
        height: 1px;
        display: block;
        background: ${darkBlue};
        position: relative;
        top: 7px;

        @media (max-width: ${mediaQueries.small}) {
          & {
            top: -3px;
          }
        }
      }
    }

    .ant-collapse-content-active {
      border-top: none;
    }
  }

  & > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
    position: relative;
    top: 3px;

    @media (max-width: ${mediaQueries.small}) {
      & {
        position: absolute;
        top: calc(50% + 3px);
        right: 10px;
        transform: translateY(-50%);
      }
    }
  }
`

export const renderPanels = (
  panelGroup: PanelGroup,
  activePanel: string,
  setActivePanel: (act: string) => void
): ReactElement => {
  const { title, panels } = panelGroup

  const accordions = _.map(
    panels,
    ([panelTitle, paragraphs]: string[], idx: number): ReactElement => {
      return (
        <Panel
          showArrow={false}
          header={panelTitle}
          key={`faq-panel-${idx}-${panelTitle}`}
          extra={
            <SVGImage
              svgProps={[KlosySVGId.crossCollapse, 'cross-accordion', 15, 15]}
            />
          }
        >
          {_.map(
            paragraphs,
            (p: string, index: number): ReactElement => (
              <p key={index}>{p}</p>
            )
          )}
        </Panel>
      )
    }
  )

  return (
    <span className="collapse-wrapper">
      <div className="accordion-title">
        {' '}
        <span className="bold">{title}</span>{' '}
      </div>
      <StyledCollapse
        activeKey={[activePanel]}
        onChange={(active: string): void => setActivePanel(active)}
        accordion
      >
        {accordions}
      </StyledCollapse>
    </span>
  )
}
