import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const Cross: FC = (): ReactElement => (
  <symbol id={KlosySVGId.cross} viewBox="0 0 12.707 12.707" x="0" y="0">
    <g id="close" transform="translate(0.354 0.354)">
      <line
        id="Line_1"
        data-name="Line 1"
        x2="12"
        y2="12"
        fill="none"
        stroke="#1d3041"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <line
        id="Line_2"
        data-name="Line 2"
        x1="12"
        y2="12"
        fill="none"
        stroke="#1d3041"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
  </symbol>
)
