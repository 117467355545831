import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const ArrowDown: FC = (): ReactElement => (
  <symbol id={KlosySVGId.arrowDown} viewBox="0 0 19.07 11.663" x="0" y="0">
    <g
      id="arrow_right"
      data-name="arrow right"
      transform="translate(18.647 0.337) rotate(90)"
    >
      <path
        id="Path_59"
        data-name="Path 59"
        d="M555.337,228.981l10,9.139-10,9.139"
        transform="translate(-555.337 -228.981)"
        fill="none"
        stroke="#1d3041"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
  </symbol>
)
