import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const KlosyLogo: FC = (): ReactElement => (
  <symbol id={KlosySVGId.klosyLogo} viewBox="0 0 110 50">
    <g>
      <path d="M38.6,43.1l2.1-2.4h0.8l-2.2,2.5l2.4,2.6h-0.8l-2.3-2.5v2.5H38v-5.2h0.6V43.1z" />
      <path d="M45.2,40.7l2.8,4.1v-4.1h0.6v5.2H48l-2.8-4.1v4.1h-0.6v-5.2H45.2z" />
      <path d="M52.1,40.7h0.6v5.2h-0.6V40.7z" />
      <path d="M57.6,46l-2.2-5.3h0.7l1.5,3.8l1.5-3.8h0.6L57.6,46z" />
      <path d="M62.8,42.9H65v0.6h-2.2v1.8h2.4v0.6h-3v-5.2h3v0.6h-2.4V42.9z" />
      <path
        d="M69.2,43.5c-0.8-0.3-1.4-0.7-1.4-1.6c0-0.6,0.5-1.4,1.5-1.4s1.5,0.8,1.5,0.8l-0.5,0.4
			c0,0-0.4-0.5-1-0.5s-0.9,0.4-0.9,0.8c0,0.5,0.4,0.7,1.2,1.1c0.8,0.4,1.3,0.7,1.3,1.5S70.2,46,69.4,46c-1.2,0-1.6-1.1-1.6-1.1
			l0.5-0.3c0,0,0.3,0.8,1.1,0.8c0.5,0,1-0.3,1-0.8C70.2,44,69.8,43.8,69.2,43.5z"
      />
    </g>
    <g>
      <path
        d="M24.2,30.1l-7.9-10.3l-3.1,3.5c-1,1.1-1.5,2.3-1.5,3.5v3.3h-1.1V10.8h1.1v12.7
			c0.2-0.4,0.5-0.7,0.7-0.9L23,10.8h1.4L17.1,19l8.5,11.1H24.2z"
      />
      <polygon
        points="30.5,30.1 30.5,22.9 28.5,24.1 28.5,22.9 30.5,21.6 30.5,10.8 31.6,10.8 31.6,21
			38.4,16.8 38.4,18 31.6,22.2 31.6,29 41.7,29 41.7,30.1"
      />
      <path
        d="M53.9,30.3c-5.4,0-9.8-4.4-9.8-9.8c0-5.4,4.4-9.9,9.8-9.9s9.9,4.4,9.9,9.9
			C63.7,25.9,59.2,30.3,53.9,30.3z M53.9,11.7c-4.8,0-8.8,3.9-8.8,8.8c0,4.8,3.9,8.8,8.8,8.8c4.8,0,8.8-3.9,8.8-8.8
			C62.6,15.6,58.7,11.7,53.9,11.7z"
      />
      <path
        d="M74,30.3c-3.1,0-5.7-1.3-6.9-3.5L67,26.6l1-0.5l0.1,0.2c1.1,1.8,3.4,2.9,6,2.9
			c2.2,0,5.9-0.8,5.9-4c0-3-3.2-3.9-6-4.8h-0.1c-2.7-0.8-6.1-1.9-6.1-5.2c0-3.1,3.3-4.6,6.3-4.6c2.3,0,4.6,0.9,6.1,2.4l0.2,0.2
			l-0.8,0.7l-0.2-0.2c-1.3-1.3-3.3-2.1-5.4-2.1c-2.5,0-5.2,1.1-5.2,3.5c0,2.3,2.2,3.2,5.3,4.2c3.2,1,6.9,2.2,6.9,5.9
			c0,1.7-0.8,3.1-2.4,4C77.4,29.9,75.7,30.3,74,30.3z"
      />
      <polygon
        points="90.8,30.1 90.8,23.7 83.1,10.8 84.4,10.8 91.2,22.5 98.2,10.8 99.4,10.8
			91.8,23.7 91.8,30.1"
      />
    </g>
  </symbol>
)
