import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const HamburgerMenu: FC = (): ReactElement => (
  <symbol id={KlosySVGId.hamburger} viewBox="0 0 18 15" x="0" y="0">
    <defs>
      <clipPath id="clip-path">
        <rect width="18" height="15" fill="none" />
      </clipPath>
    </defs>
    <g
      id="menu_Repeat_Grid_12"
      data-name="menu Repeat Grid 12"
      clipPath="url(#clip-path)"
    >
      <g transform="translate(-230.5 -46)">
        <line
          id="Line_95"
          data-name="Line 95"
          x2="17.5"
          transform="translate(230.5 46.5)"
          fill="none"
          stroke="#1d3041"
          strokeWidth="1"
        />
      </g>
      <g transform="translate(-230.5 -39)">
        <line
          id="Line_95-2"
          data-name="Line 95"
          x2="17.5"
          transform="translate(230.5 46.5)"
          fill="none"
          stroke="#1d3041"
          strokeWidth="1"
        />
      </g>
      <g transform="translate(-230.5 -32)">
        <line
          id="Line_95-3"
          data-name="Line 95"
          x2="17.5"
          transform="translate(230.5 46.5)"
          fill="none"
          stroke="#1d3041"
          strokeWidth="1"
        />
      </g>
    </g>
  </symbol>
)
