import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const Klos: FC = (): ReactElement => (
  <symbol id={KlosySVGId.klos} viewBox="-460 175 39 212" x="0" y="0">
    <polygon
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      points="
	-438.5,286.6 -424.3,275.1 -426.1,272.6 -438.5,282.6 -438.5,265.8 -424.3,254.3 -426.1,251.9 -438.5,261.8 -438.5,245.1
	-424.3,233.5 -426.1,231.1 -438.5,241.1 -438.5,224.3 -424.3,212.8 -426.1,210.3 -438.5,220.3 -438.5,203.6 -424.3,192
	-426.1,189.5 -438.5,199.6 -438.5,180.5 -441.5,180.5 -441.5,199.6 -454.2,189.5 -456.1,192 -441.5,203.6 -441.5,220.3
	-454.2,210.3 -456.1,212.8 -441.5,224.3 -441.5,241.1 -454.2,231.1 -456.1,233.5 -441.5,245.1 -441.5,261.8 -454.2,251.9
	-456.1,254.3 -441.5,265.8 -441.5,282.6 -454.2,272.6 -456.1,275.1 -441.5,286.6 -441.5,381.5 -438.5,381.5 "
    />
  </symbol>
)
