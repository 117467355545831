import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const Checked: FC = (): ReactElement => (
  <symbol id={KlosySVGId.checked} viewBox="0 0 15 15" x="0" y="0">
    <g id="Group_297" data-name="Group 297" transform="translate(-635 -331)">
      <g
        id="Rectangle_1488"
        data-name="Rectangle 1488"
        transform="translate(635 331)"
        fill="#1d3041"
        stroke="#1d3041"
        strokeWidth="1"
      >
        <rect width="15" height="15" stroke="none" />
        <rect x="0.5" y="0.5" width="14" height="14" fill="none" />
      </g>
      <path
        id="Checkbox"
        d="M4.576,7.958,0,3.382l.928-.928L4.576,6.035,10.611,0l.928.928Z"
        transform="translate(637 335)"
        fill="#f7f5f1"
      />
    </g>
  </symbol>
)
