import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const LogoCircle: FC = (): ReactElement => (
  <symbol id={KlosySVGId.logoCircle} viewBox="0 0 100 100">
    <path
      d="M8.4,63.3l-0.3-0.8l6.7-2.2l0.3,0.8l-3,1l1.1,3.3l3-1l0.3,0.8l-6.7,2.2l-0.3-0.8l2.9-0.9
		l-1.1-3.3L8.4,63.3z"
    />
    <path
      d="M6.6,54.1v-0.8l6.9-3v0.9L11.6,52l0.1,3l1.9,0.7v0.9L6.6,54.1z M10.9,54.7l-0.1-2.4
		l-3.1,1.3L10.9,54.7z"
    />
    <path
      d="M6.8,44l6.3-2.5l-5.5-1.2l0.2-0.8l6.8,1.5l-0.2,0.8l-6.3,2.5l5.5,1.2l-0.2,0.8l-6.8-1.5
		L6.8,44z"
    />
    <path
      d="M15.4,30.1c1.8,0.9,2.5,2.8,1.5,4.7L16,36.6l-6.2-3.3l0.9-1.8
		C11.7,29.6,13.6,29.1,15.4,30.1z M15,30.9c-1.3-0.7-2.8-0.5-3.6,1l-0.5,1l4.8,2.5l0.5-1C16.9,32.9,16.3,31.5,15,30.9z"
    />
    <path
      d="M16.2,22.5l4.5,1l-1.1-4.4l0.6-0.6l5,4.9L24.6,24l-3.9-3.8l1.1,4.4l-4.4-1l3.9,3.8
		L20.7,28l-5-4.9L16.2,22.5z"
    />
    <path
      d="M26.8,13.2l0.7-0.4l5.8,4.7l-0.8,0.4l-1.6-1.3l-2.6,1.5l0.3,2l-0.8,0.5L26.8,13.2z
		 M28.3,17.3l2.1-1.2L27.8,14L28.3,17.3z"
    />
    <path
      d="M41.2,10.9c0.5,1.9-0.3,3.7-2.4,4.3l-1.9,0.5l-1.8-6.8L37,8.4C39,7.8,40.7,8.9,41.2,10.9z
		 M40.3,11.1c-0.4-1.4-1.6-2.4-3.2-1.9L36,9.5l1.4,5.2l1.1-0.3C40.2,13.9,40.7,12.5,40.3,11.1z"
    />
    <path d="M47,9.4h3v0.8h-3v2.4h3.3v0.8l-4.1,0.1l-0.1-7l4-0.1v0.8h-3.2L47,9.4z" />
    <path
      d="M71.1,15.1l4.2-1.1l0.8,0.6l-4.3,1.1l0.6,4.8l-0.9-0.6l-0.6-4.5L69,18.1l-0.7-0.4l4-5.7
		l0.7,0.4L71.1,15.1z"
    />
    <path d="M80.5,18.1l0.5,0.6l-5,4.9L75.5,23L80.5,18.1z" />
    <path d="M85.3,24.6L84.1,23l0.7-0.4l2.8,4L86.9,27l-1.1-1.6l-5.1,3.6l-0.4-0.7L85.3,24.6z" />
    <path
      d="M90.6,36.4c0,0,0.5-0.9,0.1-2.1c-0.5-1.5-2.1-2-3.5-1.4c-1.4,0.5-2.3,2-1.8,3.4
		c0.5,1.2,1.6,1.6,1.6,1.6l-0.3,0.7c0,0-1.4-0.5-1.9-2.1c-0.7-1.9,0.2-3.8,2.2-4.5c1.9-0.7,3.9,0,4.7,2c0.6,1.6-0.1,2.7-0.1,2.7
		L90.6,36.4z"
    />
    <path
      d="M94.3,46.6l0.1,0.8l-7,0.6l-0.1-0.8l3.2-0.2l-0.2-3.5l-3.2,0.2L87,42.9l7-0.6l0.1,0.8
		l-3,0.2l0.2,3.5L94.3,46.6z"
    />
    <path
      d="M91.3,54.1L90.9,57l-0.8-0.1l0.4-2.9l-2.3-0.3l-0.5,3.2l-0.8-0.1l0.6-4.1l6.9,1l-0.6,4
		L93,57.5l0.5-3.1L91.3,54.1z"
    />
    <path
      d="M92.3,64.6l-6.6,1.2l5.1,2.2l-0.3,0.7L84,65.9l0.3-0.8l6.6-1.2l-5.1-2.2l0.3-0.7l6.4,2.9
		L92.3,64.6z"
    />
    <path
      d="M75.2,82.8l-0.6,4.3l-0.9,0.6l0.5-4.5L69.6,82l1-0.6l4.4,1.1l-1.9-2.8l0.7-0.5l3.8,5.9
		l-0.7,0.5L75.2,82.8z"
    />
    <path
      d="M67.8,90.9l-5.4-4l1.9,5.2l-0.8,0.3l-2.3-6.6l0.9-0.3l5.4,4l-1.9-5.2l0.8-0.3l2.3,6.6
		L67.8,90.9z"
    />
    <path d="M57.3,93.8l-0.8,0.1l-0.8-7l0.8-0.1L57.3,93.8z" />
    <path d="M48.8,87.2l2.2,7.4L50,94.5l-1.6-5.3L46,94.1l-0.8,0L48.8,87.2z" />
    <path
      d="M40,90.1l-2.8-0.9l0.3-0.8l2.8,0.9L41,87l-3.1-1l0.3-0.8l3.9,1.3L40,93.1l-3.8-1.3l0.3-0.8
		l3.1,1L40,90.1z"
    />
    <path
      d="M31,85.4c0.7,0.9,1.3,1.8,0.7,2.8c-0.4,0.7-1.5,1.3-2.6,0.6S27.7,87,27.7,87l0.8-0.2
		c0,0,0.1,0.9,0.9,1.3c0.7,0.4,1.2,0.1,1.6-0.3c0.4-0.6,0-1.1-0.7-2c-0.7-1-1-1.6-0.5-2.6s1.7-1.2,2.8-0.6c1.5,0.8,1.3,2.4,1.3,2.4
		L33.1,85c0,0,0.2-1.2-0.8-1.7c-0.6-0.3-1.3-0.4-1.7,0.3C30.1,84.2,30.4,84.7,31,85.4z"
    />
  </symbol>
)
