import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationEn from '../locales/en/translations.json'
import translationPl from '../locales/pl/translations.json'

const branchName = (process.env.BRANCH || '').toUpperCase()
const languageVersion = branchName === 'EN' ? 'EN' : 'PL'

i18n.use(initReactI18next).init({
  fallbackLng: 'EN',
  debug:
    typeof window !== 'undefined' && window.location.href.includes('localhost'),
  lng: languageVersion,

  resources: {
    EN: {
      translation: translationEn,
    },
    PL: {
      translation: translationPl,
    },
  },

  interpolation: {
    escapeValue: false,
  },
})

export default i18n
