import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const InfoSign: FC = (): ReactElement => (
  <symbol id={KlosySVGId.infoSign}>
    <g
      id="Group_225"
      data-name="Group 225"
      transform="translate(-144.793 -386.69)"
    >
      <path
        id="Path_405"
        data-name="Path 405"
        d="M152.652,392.462a.867.867,0,0,1-.264-.636.853.853,0,0,1,.264-.623.862.862,0,0,1,.636-.265.883.883,0,0,1,.641.265.843.843,0,0,1,.27.623.857.857,0,0,1-.27.636.889.889,0,0,1-.641.263A.868.868,0,0,1,152.652,392.462Zm-.036,1.127h1.343v5.853h-1.343Z"
        fill="#1d3041"
      />
    </g>
    <g
      id="Ellipse_265"
      data-name="Ellipse 265"
      fill="none"
      stroke="#1d3041"
      strokeMiterlimit="10"
      strokeWidth="1.214"
    >
      <circle cx="8.5" cy="8.5" r="8.5" stroke="none" />
      <circle cx="8.5" cy="8.5" r="7.893" fill="none" />
    </g>
  </symbol>
)
