export const palette = {
  lightBlue: '#3A4A71',
  darkBlue: '#1D3043',
  borderColor: '#485766',
  darkGray: '#0E2236',
  navyBlue: '#172431',
  beige: '#EAE6DD',
  darkBiege: '#6B6965',
  inputBorder: '#AAA7A1',
  paleGray: '#DDDCD9',
  smokeWhite: '#F7F5F1',
  error: '#C54644',
}

export const animatedBackground: string[] = [
  '#f2f0eb',
  '#ebe5de',
  '#dedbd9',
  '#e5d6cc',
  '#d1d6d9',
  '#e8e8e3',
]

export const screenSizes = {
  small: 599,
  medium: 1199,
  large: 1200,
}

export const mediaQueries = {
  small: `${screenSizes.small}px`,
  medium: `${screenSizes.medium}px`,
  large: `${screenSizes.large}px`,
}

export const paragraphSize = '16px'

export const appMaxWidthRaw = 1024
export const appMaxWidth = `${appMaxWidthRaw}px`

export const smallScreenOffset = { span: 20, offset: 2 }

export const letterSpacing = '2px'
