import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const LogoMini: FC = (): ReactElement => (
  <symbol id={KlosySVGId.logoMini} viewBox="0 0 9.489 24.13" x="0" y="0">
    <svg xmlns="http://www.w3.org/2000/svg">
      <path
        id="Path_275"
        data-name="Path 275"
        d="M0,0V5.652L-3.7,2.679l-.584.731L0,6.841V11.8L-3.7,8.842l-.584.721L0,12.993v4.974l-3.7-2.972-.584.731L0,19.156V24.13H.929V19.156L5.2,15.726l-.573-.731-3.7,2.972V12.993L5.2,9.563l-.573-.721L.929,11.8V6.841L5.2,3.41l-.573-.731L.929,5.652V0Z"
        transform="translate(4.285)"
        fill="#1d2f41"
      />
    </svg>
  </symbol>
)
