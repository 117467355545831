import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const ErrorSign: FC = (): ReactElement => (
  <symbol id={KlosySVGId.errorSign} viewBox="0 0 17 17" x="0" y="0">
    <circle
      id="Ellipse_266"
      data-name="Ellipse 266"
      cx="8.5"
      cy="8.5"
      r="8.5"
      fill="#1d3041"
    />
    <g
      id="Group_266"
      data-name="Group 266"
      transform="translate(161.793 403.69) rotate(180)"
    >
      <path
        id="Path_405"
        data-name="Path 405"
        d="M152.652,392.462a.867.867,0,0,1-.264-.636.853.853,0,0,1,.264-.623.862.862,0,0,1,.636-.265.883.883,0,0,1,.641.265.843.843,0,0,1,.27.623.857.857,0,0,1-.27.636.889.889,0,0,1-.641.263A.868.868,0,0,1,152.652,392.462Zm-.036,1.127h1.343v5.853h-1.343Z"
        fill="#f7f5f1"
      />
    </g>
  </symbol>
)
