import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const Minus: FC = (): ReactElement => (
  <symbol id={KlosySVGId.minus} viewBox="0 0 17 17" x="0" y="0">
    <g id="Group_294" data-name="Group 294" transform="translate(-792 -330)">
      <g
        id="Ellipse_267"
        data-name="Ellipse 267"
        transform="translate(792 330)"
        fill="#fff"
        stroke="#1d3041"
        strokeWidth="1"
      >
        <circle cx="8.5" cy="8.5" r="8.5" stroke="none" />
        <circle cx="8.5" cy="8.5" r="8" fill="none" />
      </g>
      <g
        id="Group_291"
        data-name="Group 291"
        transform="translate(-181.283 -28.325)"
      >
        <g
          id="Group_225"
          data-name="Group 225"
          transform="translate(1378.872 213.538) rotate(90)"
        >
          <path
            id="Path_405"
            data-name="Path 405"
            d="M152.616,393.589h1.343v7h-1.343Z"
            fill="#1d3041"
          />
        </g>
      </g>
    </g>
  </symbol>
)
