import React, { FC, ReactElement } from 'react'
import { KlosySVGId } from '~components/homepage/svg/types/svg'

type AspectRatio =
  | 'xMidYMid meet'
  | 'xMinYMid meet'
  | 'xMaxYMid meet'
  | 'xMidYMin slice'
  | 'xMidYMid slice'
  | 'xMidYMax slice'
  | 'xMidYMin meet'
  | 'xMidYMid meet'
  | 'xMidYMax meet'
  | 'xMinYMid slice'
  | 'xMidYMid slice'
  | 'xMaxYMid slice'
  | 'none'

interface Props {
  className?: string
  preserveAspectRatio?: AspectRatio
  onClick?: Function
  svgProps: SvgBasicProps
}

interface SvgBasicProps {
  0: KlosySVGId
  1: string
  2: number | undefined
  3: number
}

export const SVGImage: FC<Props> = ({
  className,
  preserveAspectRatio = 'xMidYMid meet',
  svgProps,
}): ReactElement => {
  // @ts-ignore
  const [svgId, title, width, height] = svgProps

  return (
    <svg
      className={className}
      width={width}
      height={height}
      preserveAspectRatio={preserveAspectRatio}
    >
      {title && <title>{title}</title>}
      <use xlinkHref={`#${svgId}`} />
    </svg>
  )
}

export type SVGImageProps = Props
