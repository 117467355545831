import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const KlosSide: FC = (): ReactElement => (
  <symbol id={KlosySVGId.klosSide} viewBox="0 0 68.554 10.94" x="0" y="0">
    <g id="Group_139" data-name="Group 139" transform="translate(64.623)">
      <path
        id="Path_293"
        data-name="Path 293"
        d="M0,0-3.955,4.941H-9.678L-6.264.674-7.094,0l-3.955,4.941h-5.734L-13.358.674-14.2,0l-3.956,4.941h-5.723L-20.462.674-21.292,0l-3.956,4.941h-5.734L-27.556.674-28.4,0l-3.943,4.941H-64.623V6.012h32.282L-28.4,10.94l.842-.661-3.426-4.267h5.734l3.956,4.928.83-.661-3.416-4.267h5.723L-14.2,10.94l.841-.661-3.425-4.267h5.734l3.955,4.928.83-.661L-9.678,6.012h5.723L0,10.94l.842-.661L-2.586,6.012H3.931V4.941H-2.586L.842.674Z"
        fill="#1d2f41"
      />
    </g>
  </symbol>
)
