import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const CrossCollapse: FC = (): ReactElement => (
  <symbol id={KlosySVGId.crossCollapse} viewBox="0 0 17.97 17.971">
    <g id="close" transform="translate(8.985 0.5) rotate(45)">
      <line
        id="Line_1"
        data-name="Line 1"
        x2="12"
        y2="12"
        fill="none"
        stroke="#1d3041"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <line
        id="Line_2"
        data-name="Line 2"
        x1="12"
        y2="12"
        fill="none"
        stroke="#1d3041"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
  </symbol>
)
