import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const Star: FC = (): ReactElement => (
  <symbol id={KlosySVGId.star} viewBox="0 0 8.074 8.173" x="0" y="0">
    <g
      id="Group_267"
      data-name="Group 267"
      transform="translate(-219.21 -295.127)"
    >
      <path
        id="Path_430"
        data-name="Path 430"
        d="M222.385,300.751l-2.15,1.35-1-1.725,2.25-1.149-2.275-1.2,1.025-1.725,2.15,1.375-.125-2.55h2l-.15,2.55,2.175-1.35,1,1.7-2.25,1.174,2.25,1.175-1,1.725-2.1-1.35.075,2.549h-2Z"
        fill="#1d3041"
      />
    </g>
  </symbol>
)
