import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { mediaQueries } from '../styled/constants'

const GlobalStyle = createGlobalStyle`



@media (max-width: ${mediaQueries.small}) {
  .ant-modal-wrap.workshop-modal {
    overflow: hidden;

    .ant-modal {
      margin: 0px !important;
      max-width: 100vw;
      height: 100%;
      padding: 0px;

      .ant-modal-content {
        height: 100%;
        overflow-y: auto;
      }
    }
  }
}

b {
  font-family: 'Averta-Bold', sans-serif;
}

span.bold {
  font-family: 'Averta-Bold', sans-serif;
}

i {
  font-family: 'Averta-RegularItalic', sans-serif;
}

.ant-modal-mask {
  background-color: rgba(255,255,255, .9) !important;
}

.description-modal {
  display: none;

  & ~ .ant-modal-mask {
    display: none;
  }

  .ant-modal-body {
    
      .ant-row-flex {
          height: 100%;
          overflow-y: auto; 
      }
  }

  @media (max-width: ${mediaQueries.small}) {
        & {
            display: block;
        }

        & ~ .ant-modal-mask {
          display: block;
        }
    }
}

  body {
      /* overflow: auto !important;  */
  }

`
export default (): React.ReactElement => <GlobalStyle />
