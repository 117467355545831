import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const Arrow: FC = (): ReactElement => (
  <symbol id={KlosySVGId.arrow} viewBox="0 0 11.07 18.663" x="0" y="0">
    <g
      id="arrow_left"
      data-name="arrow left"
      transform="translate(0.741 0.362)"
    >
      <path
        id="Path_58"
        data-name="Path 58"
        d="M549.338,246.832l-10-8.751,9.614-9.174"
        transform="translate(-539.338 -228.908)"
        fill="none"
        stroke="#1d3041"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
  </symbol>
)

export const ArrowSvg: FC = (): ReactElement => (
  <svg viewBox="0 0 11.07 18.663" x="0" y="0">
    <g
      id="arrow_left"
      data-name="arrow left"
      transform="translate(0.741 0.362)"
    >
      <path
        id="Path_58"
        data-name="Path 58"
        d="M549.338,246.832l-10-8.751,9.614-9.174"
        transform="translate(-539.338 -228.908)"
        fill="none"
        stroke="#1d3041"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
  </svg>
)
